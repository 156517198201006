body {
  font-family: 'Arial', sans-serif;
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;
  margin: 0;
  padding: 0;
  color: #333;
}

.home-container {
  text-align: center;
  padding: 20px;
  max-width: 600px;
  margin: auto;
  background-color: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.home-container h1 {
  padding-bottom: 10px;
}

.home-container img {
  width: 100%;
  max-width: 500px;
  border-radius: 10px;
}

.header-container {
  /* background-color: rgb(255, 255, 221); */
  background: linear-gradient(to right, #ffe6e6, #fff6cb);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 0px 10px 10px 10px; */
  padding-top: -10px;
  margin: 20px 75px 10px 75px;
  border-radius: 20px;
  box-sizing: border-box;
  /* top: -30px; */
}

.header-container h2,
.header-container h1 {
  color: #333;
}

.header-container p {
  color: #666;
}

.form-container {
  margin: 20px 0;
}

.dropdown {
  padding: 10px;
  margin-top: 10px;
  width: 100%;
  max-width: 300px;
  border: 2px solid #ff4e50;
  border-radius: 5px;
  font-size: 16px;
  color: #8a8a8a;
  background: #fff;
  cursor: pointer;
  transition: border-color 0.2s, color 0.2s;
}

.category-select {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.category-select button {
  margin: 10px;
  padding: 10px 20px;
  background-color: #0078ff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.category-select button:hover {
  background-color: #005bb5;
}

.div-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 30px;
}

.div-container-first,
.div-container-second {
  width: 85%;
  background-color: #f1f1f1;
  border-radius: 10px;
  padding: 15px 20px;
  text-align: left;
  margin: 10px 0;
}

.div-container-first {
  background-color: #f1f1f1;
}

.div-container-second {
  background-color: #fff5ea;
}

.div-container-first h3,
.div-container-second h3 {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.div-container-first p,
.div-container-second p {
  font-size: 1em;
  color: #444;
}

footer {
  padding-top: 20px;
  font-size: 13px;
  text-align: center;
}

footer a {
  color: #60aaff;
  text-decoration: none;
  margin: 0 10px;
}

footer a:hover {
  text-decoration: underline;
}
