body {
  font-family: 'Arial', sans-serif;
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;
  margin: 0;
  padding: 0;
  color: #333;
}

.chat-container {
  width: 100%;
  max-width: 800px;
  height: 100vh;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Ensure no overflow from the container itself */
  /* border: 1px solid #e0e0e0; */
}

.chat-header {
  padding: 10px;
  background: linear-gradient(to bottom, #ff4e50, #ffd000);
  color: white;
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-body {
  flex: 1;
  padding: 20px;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background-color: #fff8e1;
}

.chat-footer {
  display: flex;
  padding: 10px;
  background-color: #f9f9f9;
  border-top: 1px solid #ddd;
}

.chat-footer input {
  flex: 1;
  width: 200px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 20px;
  margin-right: 10px;
}

.chat-footer button {
  padding: 10px 20px;
  border: none;
  background-color: #ff4e50;
  color: white;
  border-radius: 20px;
  cursor: pointer;
}

.chat-footer button:hover {
  background-color: #ff6f61;
}

.message {
  margin-bottom: 15px;
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 75%;
  word-wrap: break-word;
}

.message.user {
  background-color: #dcf8c6;
  align-self: flex-end;
}

.message.bot {
  background-color: #fff;
  align-self: flex-start;
  border: 1px solid #ddd;
}

.message img.profile-pic {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}
.profile-text {
  display: flex;
  gap: 10px;
}
.dropdown-container p {
  display: flex;
  justify-content: center;
}

.product-link-button {
  background-color: #ff4e50;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 10px;
}

.product-link-button:hover {
  background-color: #ff6f61;
}

footer {
  padding-top: 20px;
  font-size: 13px;
  text-align: center;
}

footer a {
  color: #60aaff;
  text-decoration: none;
  margin: 0 10px;
}

footer a:hover {
  text-decoration: underline;
}

.more-suggestions-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.more-suggestions-button {
  display: inline-block;
  padding: 8px 15px;
  background-color: #0078ff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.more-suggestions-button:hover {
  background-color: #005bb5;
}
